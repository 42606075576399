<template>
  <div class="page_content page_content_system">
    <div class="content_header d-flex align-items-center">
      <span>系統管理</span>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="d-flex mb-3">
          <a
            v-if="!edit_mode"
            href="#"
            class="btn btn_primary px-2"
            @click.prevent="init_detail('admin', true), init_validate()"
          >
            <span>新增管理者</span>
          </a>
          <a
            v-if="!edit_mode"
            href="#"
            class="btn btn_primary mx-2 px-2"
            @click.prevent="init_detail('group', true), init_validate()"
          >
            <span>新增群組</span>
          </a>
          <a
            v-else
            href="#"
            class="btn btn_primary ml-auto px-2"
            @click.prevent="init_validate(), init_detail(''), get_admin_list()"
          >
            <span>返回</span>
          </a>
        </div>
        <ul
          v-if="!edit_mode"
          class="nav nav-tabs"
        >
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': nav_tabs === 'admins' }"
              @click.prevent="switch_nav('admins')"
            >管理者</a>
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': nav_tabs === 'groups' }"
              @click.prevent="switch_nav('groups')"
            >群組</a>
          </li>
        </ul>
        <div
          v-if="!edit_mode && nav_tabs === 'admins'"
          class="table-responsive"
        >
          <table class="table table-borderless">
            <thead class="thead-light">
              <tr>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  管理者名稱
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  所屬群組
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  Email
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(admin, index) in sort_admin"
                :key="index"
              >
                <td>
                  <a
                    href="#"
                    class="text-color_primary"
                    @click.prevent="get_admin_detail(admin)"
                  >
                    {{ admin.adminName }}
                  </a>
                </td>
                <td class="">
                  <div>
                    {{ admin.groupCode }}
                  </div>
                </td>
                <td class="">
                  <div>{{ admin.email }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="!edit_mode && nav_tabs === 'groups'"
          class="table-responsive"
        >
          <table class="table table-borderless">
            <thead class="thead-light">
              <tr>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  群組名稱
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  倉儲
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  客戶
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  通路
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  管理者
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  商品
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  訂單(客/通)
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  優惠
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  兌換
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  消息
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  網站
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  門市
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  系統
                </th>
                <th
                  scope="col"
                  width=""
                  class=""
                >
                  分析
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(group, index) in groups"
                :key="index"
              >
                <td>
                  <a
                    href="#"
                    class="text-color_primary"
                    @click.prevent="get_group_detail(group)"
                  >
                    {{ group.groupName }}
                  </a>
                </td>
                <td class="">
                  <i
                    v-if="group.authDepot === 0"
                    class="icon-cross text-danger"
                  />
                  <i
                    v-if="group.authDepot === 1"
                    class="icon-alert-triangle text-warning"
                  />
                  <i
                    v-if="group.authDepot === 2"
                    class="icon-circle text-success"
                  />
                </td>
                <td class="">
                  <i
                    v-if="group.authCustomer === 0"
                    class="icon-cross text-danger"
                  />
                  <i
                    v-if="group.authCustomer === 1"
                    class="icon-alert-triangle text-warning"
                  />
                  <i
                    v-if="group.authCustomer === 2"
                    class="icon-circle text-success"
                  />
                </td>
                <td class="">
                  <i
                    v-if="group.authChannel === 0"
                    class="icon-cross text-danger"
                  />
                  <i
                    v-if="group.authChannel === 1"
                    class="icon-alert-triangle text-warning"
                  />
                  <i
                    v-if="group.authChannel === 2"
                    class="icon-circle text-success"
                  />
                </td>
                <td class="">
                  <i
                    v-if="group.authAdmin === 0"
                    class="icon-cross text-danger"
                  />
                  <i
                    v-if="group.authAdmin === 1"
                    class="icon-alert-triangle text-warning"
                  />
                  <i
                    v-if="group.authAdmin === 2"
                    class="icon-circle text-success"
                  />
                </td>
                <td class="">
                  <i
                    v-if="group.authProduct === 0"
                    class="icon-cross text-danger"
                  />
                  <i
                    v-if="group.authProduct === 1"
                    class="icon-alert-triangle text-warning"
                  />
                  <i
                    v-if="group.authProduct === 2"
                    class="icon-circle text-success"
                  />
                </td>
                <td class="">
                  <div class="d-flex align-items-center">
                    <i
                      v-if="group.authOrderCustomer === 0"
                      class="icon-cross text-danger"
                    />
                    <i
                      v-if="group.authOrderCustomer === 1"
                      class="icon-alert-triangle text-warning"
                    />
                    <i
                      v-if="group.authOrderCustomer === 2"
                      class="icon-circle text-success"
                    />
                    <span class="mx-1">/</span>
                    <i
                      v-if="group.authOrderChannel === 0"
                      class="icon-cross text-danger"
                    />
                    <i
                      v-if="group.authOrderChannel === 1"
                      class="icon-alert-triangle text-warning"
                    />
                    <i
                      v-if="group.authOrderChannel === 2"
                      class="icon-circle text-success"
                    />
                  </div>
                </td>
                <td class="">
                  <i
                    v-if="group.authSaleEvent === 0"
                    class="icon-cross text-danger"
                  />
                  <i
                    v-if="group.authSaleEvent === 1"
                    class="icon-alert-triangle text-warning"
                  />
                  <i
                    v-if="group.authSaleEvent === 2"
                    class="icon-circle text-success"
                  />
                </td>
                <td class="">
                  <i
                    v-if="group.authCoupon === 0"
                    class="icon-cross text-danger"
                  />
                  <i
                    v-if="group.authCoupon === 1"
                    class="icon-alert-triangle text-warning"
                  />
                  <i
                    v-if="group.authCoupon === 2"
                    class="icon-circle text-success"
                  />
                </td>
                <td class="">
                  <i
                    v-if="group.authNews === 0"
                    class="icon-cross text-danger"
                  />
                  <i
                    v-if="group.authNews === 1"
                    class="icon-alert-triangle text-warning"
                  />
                  <i
                    v-if="group.authNews === 2"
                    class="icon-circle text-success"
                  />
                </td>
                <td class="">
                  <i
                    v-if="group.authSite === 0"
                    class="icon-cross text-danger"
                  />
                  <i
                    v-if="group.authSite === 1"
                    class="icon-alert-triangle text-warning"
                  />
                  <i
                    v-if="group.authSite === 2"
                    class="icon-circle text-success"
                  />
                </td>
                <td class="">
                  <i
                    v-if="group.authStore === 0"
                    class="icon-cross text-danger"
                  />
                  <i
                    v-if="group.authStore === 1"
                    class="icon-alert-triangle text-warning"
                  />
                  <i
                    v-if="group.authStore === 2"
                    class="icon-circle text-success"
                  />
                </td>
                <td class="">
                  <i
                    v-if="group.authSystem === 0"
                    class="icon-cross text-danger"
                  />
                  <i
                    v-if="group.authSystem === 1"
                    class="icon-alert-triangle text-warning"
                  />
                  <i
                    v-if="group.authSystem === 2"
                    class="icon-circle text-success"
                  />
                </td>
                <td class="">
                  <i
                    v-if="group.authAnalysis === 0"
                    class="icon-cross text-danger"
                  />
                  <i
                    v-if="group.authAnalysis === 1"
                    class="icon-alert-triangle text-warning"
                  />
                  <i
                    v-if="group.authAnalysis === 2"
                    class="icon-circle text-success"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-show="edit_mode && nav_tabs === 'admins'"
          class="edit_section"
        >
          <div class="section_header mb-3">
            <span v-show="edit_section_new">建立管理者</span>
            <span v-show="!edit_section_new">編輯管理者</span>
            <a
              v-if="!edit_section_new"
              href="#"
              class="btn btn_outline_danger w-auto h-auto rounded-sm ml-auto px-2"
              @click.prevent="delete_detail('admin', admin_detail.adminId)"
            >刪除</a>
          </div>
          <div class="section_body container-fluid">
            <div class="row">
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_adminId">管理者代碼</label>
                  <input
                    id="admin_detail_adminId"
                    v-model="admin_detail.adminId"
                    v-validate="'required'"
                    type="text"
                    name="管理者代碼"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.管理者代碼')}"
                    data-vv-scope="admin_detail"
                    placeholder="管理者代碼"
                  >
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_adminName">管理者名稱</label>
                  <input
                    id="admin_detail_adminName"
                    v-model="admin_detail.adminName"
                    v-validate="'required'"
                    type="text"
                    name="管理者名稱"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.管理者名稱')}"
                    data-vv-scope="admin_detail"
                    placeholder="管理者名稱"
                  >
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_password">密碼</label>
                  <div v-if="!edit_section_new && admin_detail.adminId !== login.admin_id">
                    不顯示
                  </div>
                  <input
                    v-else
                    id="admin_detail_password"
                    v-model="admin_detail.password"
                    v-validate="'required'"
                    type="text"
                    name="密碼"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.密碼')}"
                    data-vv-scope="admin_detail"
                    placeholder="密碼"
                  >
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_celnum">手機</label>
                  <input
                    id="admin_detail_celnum"
                    v-model="admin_detail.celnum"
                    type="text"
                    name="手機"
                    class="form-control form_input"
                    placeholder="手機"
                  >
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_email">email</label>
                  <input
                    id="admin_detail_email"
                    v-model="admin_detail.email"
                    v-validate="'required'"
                    type="text"
                    name="email"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.email')}"
                    data-vv-scope="admin_detail"
                    placeholder="email"
                  >
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_groupCode">所屬群組</label>
                  <select
                    id="admin_detail_groupCode"
                    v-model="admin_detail.groupCode"
                    v-validate="'required'"
                    name="所屬群組"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.所屬群組')}"
                    data-vv-scope="admin_detail"
                    @change="default_group_setting(admin_detail.groupCode)"
                  >
                    <option
                      v-for="item in groups"
                      :key="item.groupCode"
                      :value="item.groupCode"
                    >
                      {{ item.groupName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_authDepot">倉儲管理</label>
                  <select
                    id="admin_detail_authDepot"
                    v-model="admin_detail.authDepot"
                    v-validate="'required'"
                    name="倉儲管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.倉儲管理')}"
                    data-vv-scope="admin_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_authCustomer">客戶管理</label>
                  <select
                    id="admin_detail_authCustomer"
                    v-model="admin_detail.authCustomer"
                    v-validate="'required'"
                    name="客戶管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.客戶管理')}"
                    data-vv-scope="admin_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_authChannel">通路管理</label>
                  <select
                    id="admin_detail_authChannel"
                    v-model="admin_detail.authChannel"
                    v-validate="'required'"
                    name="通路管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.通路管理')}"
                    data-vv-scope="admin_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_authAdmin">管理者管理</label>
                  <select
                    id="admin_detail_authAdmin"
                    v-model="admin_detail.authAdmin"
                    v-validate="'required'"
                    name="管理者管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.管理者管理')}"
                    data-vv-scope="admin_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_authProduct">商品管理</label>
                  <select
                    id="admin_detail_authProduct"
                    v-model="admin_detail.authProduct"
                    v-validate="'required'"
                    name="商品管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.商品管理')}"
                    data-vv-scope="admin_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_authOrderCustomer">客戶訂單管理</label>
                  <select
                    id="admin_detail_authOrderCustomer"
                    v-model="admin_detail.authOrderCustomer"
                    v-validate="'required'"
                    name="客戶訂單管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.客戶訂單管理')}"
                    data-vv-scope="admin_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_authOrderChannel">通路訂單管理</label>
                  <select
                    id="admin_detail_authOrderChannel"
                    v-model="admin_detail.authOrderChannel"
                    v-validate="'required'"
                    name="通路訂單管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.通路訂單管理')}"
                    data-vv-scope="admin_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_authSaleEvent">優惠活動管理</label>
                  <select
                    id="admin_detail_authSaleEvent"
                    v-model="admin_detail.authSaleEvent"
                    v-validate="'required'"
                    name="優惠活動管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.優惠活動管理')}"
                    data-vv-scope="admin_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_authCoupon">兌換活動管理</label>
                  <select
                    id="admin_detail_authCoupon"
                    v-model="admin_detail.authCoupon"
                    v-validate="'required'"
                    name="兌換活動管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.兌換活動管理')}"
                    data-vv-scope="admin_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_authNews">最新消息管理</label>
                  <select
                    id="admin_detail_authNews"
                    v-model="admin_detail.authNews"
                    v-validate="'required'"
                    name="最新消息管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.最新消息管理')}"
                    data-vv-scope="admin_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_authSite">網站管理</label>
                  <select
                    id="admin_detail_authSite"
                    v-model="admin_detail.authSite"
                    v-validate="'required'"
                    name="網站管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.網站管理')}"
                    data-vv-scope="admin_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_authStore">門市櫃位管理</label>
                  <select
                    id="admin_detail_authStore"
                    v-model="admin_detail.authStore"
                    v-validate="'required'"
                    name="門市櫃位管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.門市櫃位管理')}"
                    data-vv-scope="admin_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_authSystem">系統管理</label>
                  <select
                    id="admin_detail_authSystem"
                    v-model="admin_detail.authSystem"
                    v-validate="'required'"
                    name="系統管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.系統管理')}"
                    data-vv-scope="admin_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_authAnalysis">分析</label>
                  <select
                    id="admin_detail_authAnalysis"
                    v-model="admin_detail.authAnalysis"
                    v-validate="'required'"
                    name="分析"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('admin_detail.分析')}"
                    data-vv-scope="admin_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_authCost">檢視成本</label>
                  <div class="form-check form-check-inline">
                    <input
                      id="admin_detail_authCost_true"
                      v-model="admin_detail.authCost"
                      v-validate="'required'"
                      class="form-check-input"
                      type="radio"
                      name="檢視成本"
                      :value="true"
                      data-vv-scope="admin_detail"
                    >
                    <label
                      class="form-check-label"
                      for="admin_detail_authCost_true"
                    >是</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      id="admin_detail_authCost_false"
                      v-model="admin_detail.authCost"
                      v-validate="'required'"
                      class="form-check-input"
                      type="radio"
                      name="檢視成本"
                      :value="false"
                      data-vv-scope="admin_detail"
                    >
                    <label
                      class="form-check-label"
                      for="admin_detail_authCost_false"
                    >否</label>
                  </div>
                </div>
              </div>
              <div
                v-if="!edit_section_new"
                class="col-12 col-xl-4"
              >
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_status">狀態</label>
                  <div class="form-check form-check-inline">
                    <input
                      id="admin_detail_status_true"
                      v-model="admin_detail.status"
                      v-validate="'required'"
                      class="form-check-input"
                      type="radio"
                      name="狀態"
                      :value="true"
                      data-vv-scope="admin_detail"
                    >
                    <label
                      class="form-check-label"
                      for="admin_detail_status_true"
                    >是</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      id="admin_detail_status_false"
                      v-model="admin_detail.status"
                      v-validate="'required'"
                      class="form-check-input"
                      type="radio"
                      name="狀態"
                      :value="false"
                      data-vv-scope="admin_detail"
                    >
                    <label
                      class="form-check-label"
                      for="admin_detail_status_false"
                    >否</label>
                  </div>
                </div>
              </div>
              <div
                v-if="!edit_section_new"
                class="col-12 col-xl-4"
              >
                <div class="form-group d-flex align-items-center">
                  <label for="admin_detail_dtCreate">建立日期</label>
                  <div>
                    {{ new Date(admin_detail.dtCreate).getFullYear() }}-{{ new Date(admin_detail.dtCreate).getMonth() + 1 }}-{{ new Date(admin_detail.dtCreate).getDate() }}
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <a
                v-if="!edit_section_new && admin_detail.adminId === login.admin_id"
                href="#"
                class="btn btn_outline_primary rounded-0"
                @click.prevent="update_pwd()"
              >變更密碼</a>
              <a
                href="#"
                class="btn btn_primary rounded-0"
                @click.prevent="edit_detail('admin', edit_section_new)"
              >{{ edit_section_new === true ? '建立' : '更新' }}</a>
              <a
                href="#"
                class="btn btn_default rounded-0"
                @click.prevent="init_validate(), init_detail(''), get_admin_list()"
              >關閉</a>
            </div>
          </div>
        </div>
        <div
          v-show="edit_mode && nav_tabs === 'groups'"
          class="edit_section"
        >
          <div class="section_header mb-3">
            <span v-show="edit_section_new">建立群組</span>
            <span v-show="!edit_section_new">編輯群組</span>
            <a
              v-if="!edit_section_new"
              href="#"
              class="btn btn_outline_danger w-auto h-auto rounded-sm ml-auto px-2"
              @click.prevent="delete_detail('group', group_detail.groupCode)"
            >刪除</a>
          </div>
          <div class="section_body container-fluid">
            <div class="row">
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_groupCode">群組代碼</label>
                  <input
                    id="group_detail_groupCode"
                    v-model="group_detail.groupCode"
                    v-validate="'required'"
                    type="text"
                    name="群組代碼"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('group_detail.群組代碼')}"
                    data-vv-scope="group_detail"
                    placeholder="群組代碼"
                  >
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_groupName">群組名稱</label>
                  <input
                    id="group_detail_groupName"
                    v-model="group_detail.groupName"
                    v-validate="'required'"
                    type="text"
                    name="群組名稱"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('group_detail.群組名稱')}"
                    data-vv-scope="group_detail"
                    placeholder="群組名稱"
                  >
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_authDepot">倉儲管理</label>
                  <select
                    id="group_detail_authDepot"
                    v-model="group_detail.authDepot"
                    v-validate="'required'"
                    name="倉儲管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('group_detail.倉儲管理')}"
                    data-vv-scope="group_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_authCustomer">客戶管理</label>
                  <select
                    id="group_detail_authCustomer"
                    v-model="group_detail.authCustomer"
                    v-validate="'required'"
                    name="客戶管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('group_detail.客戶管理')}"
                    data-vv-scope="group_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_authChannel">通路管理</label>
                  <select
                    id="group_detail_authChannel"
                    v-model="group_detail.authChannel"
                    v-validate="'required'"
                    name="通路管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('group_detail.通路管理')}"
                    data-vv-scope="group_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_authAdmin">管理者管理</label>
                  <select
                    id="group_detail_authAdmin"
                    v-model="group_detail.authAdmin"
                    v-validate="'required'"
                    name="管理者管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('group_detail.管理者管理')}"
                    data-vv-scope="group_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_authProduct">商品管理</label>
                  <select
                    id="group_detail_authProduct"
                    v-model="group_detail.authProduct"
                    v-validate="'required'"
                    name="商品管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('group_detail.商品管理')}"
                    data-vv-scope="group_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_authOrderCustomer">客戶訂單管理</label>
                  <select
                    id="group_detail_authOrderCustomer"
                    v-model="group_detail.authOrderCustomer"
                    v-validate="'required'"
                    name="客戶訂單管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('group_detail.客戶訂單管理')}"
                    data-vv-scope="group_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_authOrderChannel">通路訂單管理</label>
                  <select
                    id="group_detail_authOrderChannel"
                    v-model="group_detail.authOrderChannel"
                    v-validate="'required'"
                    name="通路訂單管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('group_detail.通路訂單管理')}"
                    data-vv-scope="group_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_authSaleEvent">優惠活動管理</label>
                  <select
                    id="group_detail_authSaleEvent"
                    v-model="group_detail.authSaleEvent"
                    v-validate="'required'"
                    name="優惠活動管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('group_detail.優惠活動管理')}"
                    data-vv-scope="group_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_authCoupon">兌換活動管理</label>
                  <select
                    id="group_detail_authCoupon"
                    v-model="group_detail.authCoupon"
                    v-validate="'required'"
                    name="兌換活動管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('group_detail.兌換活動管理')}"
                    data-vv-scope="group_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_authNews">最新消息管理</label>
                  <select
                    id="group_detail_authNews"
                    v-model="group_detail.authNews"
                    v-validate="'required'"
                    name="最新消息管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('group_detail.最新消息管理')}"
                    data-vv-scope="group_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_authSite">網站管理</label>
                  <select
                    id="group_detail_authSite"
                    v-model="group_detail.authSite"
                    v-validate="'required'"
                    name="網站管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('group_detail.網站管理')}"
                    data-vv-scope="group_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_authStore">門市櫃位管理</label>
                  <select
                    id="group_detail_authStore"
                    v-model="group_detail.authStore"
                    v-validate="'required'"
                    name="門市櫃位管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('group_detail.門市櫃位管理')}"
                    data-vv-scope="group_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_authSystem">系統管理</label>
                  <select
                    id="group_detail_authSystem"
                    v-model="group_detail.authSystem"
                    v-validate="'required'"
                    name="系統管理"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('group_detail.系統管理')}"
                    data-vv-scope="group_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_authAnalysis">分析</label>
                  <select
                    id="group_detail_authAnalysis"
                    v-model="group_detail.authAnalysis"
                    v-validate="'required'"
                    name="分析"
                    class="form-control form_input"
                    :class="{'is-invalid': errors.has('group_detail.分析')}"
                    data-vv-scope="group_detail"
                  >
                    <option
                      v-for="item in authority_option"
                      :key="item.code"
                      :value="item.code"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="form-group d-flex align-items-center">
                  <label for="group_detail_authCost">檢視成本</label>
                  <div class="form-check form-check-inline">
                    <input
                      id="group_detail_authCost_true"
                      v-model="group_detail.authCost"
                      v-validate="'required'"
                      class="form-check-input"
                      type="radio"
                      name="檢視成本"
                      :value="true"
                      data-vv-scope="group_detail"
                    >
                    <label
                      class="form-check-label"
                      for="group_detail_authCost_true"
                    >是</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      id="group_detail_authCost_false"
                      v-model="group_detail.authCost"
                      v-validate="'required'"
                      class="form-check-input"
                      type="radio"
                      name="檢視成本"
                      :value="false"
                      data-vv-scope="group_detail"
                    >
                    <label
                      class="form-check-label"
                      for="group_detail_authCost_false"
                    >否</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <a
                href="#"
                class="btn btn_primary rounded-0"
                @click.prevent="edit_detail('group', edit_section_new)"
              >{{ edit_section_new === true ? '建立' : '更新' }}</a>
              <a
                href="#"
                class="btn btn_default rounded-0"
                @click.prevent="init_validate(), init_detail(''), get_admin_list()"
              >關閉</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  API_GET_LOGINSTATUS,
  API_CREATE_ADMIN,
  API_UPDATE_ADMIN,
  API_DELETE_ADMIN,
  API_UPDATE_ADMIN_PWD,
  API_GET_GROUP_AUTHORITY,
  API_ADD_GROUP_AUTHORITY,
  API_UPDATE_GROUP_AUTHORITY,
  API_DELETE_GROUP_AUTHORITY,
} from '@/plugins/api';

export default {
  data() {
    return {
      now_page: 'system',
      nav_tabs: 'admins',
      groups: [], /* 群組權限資料 */
      admins: [], /* 管理者列表(含細節) */
      admin_detail: {
        celnum: '',
      }, /* 管理者細節 */
      group_detail: {}, /* 群組細節 */
      edit_mode: false, /* 編輯模式 */
      edit_section_new: false, /* 編輯的區塊是否為新 */
      authority_option: [
        {
          code: 0,
          name: '禁止',
        },
        {
          code: 1,
          name: '唯讀',
        },
        {
          code: 2,
          name: '編輯',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('system', ['system', 'option']),
    ...mapGetters('admin', ['login']),
    system_data: {
      get() {
        const vm = this;
        const data = vm.system.slice();
        return data;
      },
    },
    sort_admin: {
      get() {
        const vm = this;
        const data = vm.admins.slice();
        data.sort((a, b) => {
          if (a.groupCode < b.groupCode) {
            return -1;
          }
          if (a.groupCode > b.groupCode) {
            return 1;
          }
          return 0;
        });
        return data;
      },
    },
  },
  async mounted() {
    const vm = this;
    await vm.$store.dispatch('page_load', true);
    await vm.$store.dispatch('now_page', vm.now_page);
    await vm.get_system();
    await vm.get_system_option();
    await vm.get_admin_list();
    await vm.get_group_authority();
    await vm.$store.dispatch('page_load', false);
  },
  methods: {
    ...mapActions('system', ['get_system', 'get_system_option']),
    /* 初始化 detail 內容 */
    async init_detail(target = '', isNew = false) {
      const vm = this;
      if (target === 'admin') {
        vm.admin_detail = {
          celnum: '',
        };
        vm.nav_tabs = 'admins';
      } else if (target === 'group') {
        vm.group_detail = {};
        vm.nav_tabs = 'groups';
      } else if (target === '') {
        vm.admin_detail = {
          celnum: '',
        };
        vm.group_detail = {};
        vm.nav_tabs = 'admins';
      }
      // console.log(target, isNew);
      vm.edit_mode = isNew;
      vm.edit_section_new = isNew;
    },
    /* 初始化驗證 */
    async init_validate() {
      const vm = this;
      vm.$validator.pause();
      vm.$nextTick(() => {
        vm.$validator.reset();
        vm.$validator.errors.clear('admin_detail');
        vm.$validator.errors.clear('group_detail');
        vm.$validator.fields.items.forEach((field) => field.reset());
        vm.$validator.fields.items.forEach((field) => vm.errors.remove(field));
        vm.$validator.resume();
      });
    },
    /* 切換 nav */
    async switch_nav(content) {
      const vm = this;
      vm.nav_tabs = content;
    },
    /* 取得群組權限資料 */
    async get_group_authority(groupCode = '') {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const response = await API_GET_GROUP_AUTHORITY(groupCode);
      const res = response.data;
      if (res.code === '0000') {
        vm.groups = res.data;
        await vm.init_validate();
        await vm.init_detail('');
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 取得管理者列表 */
    async get_admin_list() {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const response = await API_GET_LOGINSTATUS();
      const res = response.data;
      if (res.code === '0000') {
        vm.admins = res.data;
        await vm.init_validate();
        await vm.init_detail('');
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 顯示管理者細節 */
    async get_admin_detail(admin) {
      const vm = this;
      const adminId = localStorage.getItem('gping_adminId') || '';
      await vm.$store.dispatch('page_load', true);
      const response = await API_GET_LOGINSTATUS(admin.adminId);
      const res = response.data;
      if (res.code === '0000') {
        await vm.init_validate();
        vm.admin_detail = admin;
        vm.edit_mode = true;
        vm.edit_section_new = false;
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* 取得群組內容 */
    async get_group_detail(group) {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      await vm.init_validate();
      vm.group_detail = group;
      vm.edit_mode = true;
      vm.edit_section_new = false;
      await vm.$store.dispatch('page_load', false);
    },
    /* 刪除管理者/群組 */
    async delete_detail(target, id) {
      const vm = this;
      if (target === 'admin') {
        const isConfirm = await vm.$swal({
          title: '確定刪除管理者?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: '取消',
          confirmButtonText: '刪除',
        });
        if (isConfirm.isConfirmed) {
          await vm.$store.dispatch('page_load', true);
          const response = await API_DELETE_ADMIN(id);
          const res = response.data;
          if (res.code === '0000') {
            vm.$swal({
              icon: 'success',
              title: '成功!',
              text: res.text,
              timer: 1000,
              confirmButtonText: '關閉視窗',
            });
            await vm.get_admin_list();
          }
          await vm.$store.dispatch('page_load', false);
        }
      } else if (target === 'group') {
        const isConfirm = await vm.$swal({
          title: '確定刪除此群組?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: '取消',
          confirmButtonText: '刪除',
        });
        if (isConfirm.isConfirmed) {
          await vm.$store.dispatch('page_load', true);
          const response = await API_DELETE_GROUP_AUTHORITY(id);
          const res = response.data;
          if (res.code === '0000') {
            vm.$swal({
              icon: 'success',
              title: '成功!',
              text: res.text,
              timer: 1000,
              confirmButtonText: '關閉視窗',
            });
            await vm.get_group_authority();
          }
          await vm.$store.dispatch('page_load', false);
        }
      }
    },
    /* 編輯/更新 */
    async edit_detail(target, isnew) {
      const vm = this;
      if (target === 'admin') {
        switch (isnew) {
          case true:
            await vm.create_amdin();
            break;
          case false:
            await vm.update_admin();
            break;
          default:
            break;
        }
      } else if (target === 'group') {
        switch (isnew) {
          case true:
            await vm.create_group();
            break;
          case false:
            await vm.update_group();
            break;
          default:
            break;
        }
      }
    },
    /* 建立管理者 */
    async create_amdin() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('admin_detail');
      if (validateResult) {
        const data = vm.admin_detail;
        const response = await API_CREATE_ADMIN(data);
        const res = response.data;
        if (res.code === '0000') {
          vm.$swal({
            icon: 'success',
            title: '成功!',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_admin_list();
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 建立群組 */
    async create_group() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('group_detail');
      if (validateResult) {
        const data = vm.group_detail;
        const response = await API_ADD_GROUP_AUTHORITY(data);
        const res = response.data;
        if (res.code === '0000') {
          vm.$swal({
            icon: 'success',
            title: '成功!',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_group_authority();
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 更新管理者 */
    async update_admin() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('admin_detail');
      if (validateResult) {
        const data = vm.admin_detail;
        delete data.dtCreate;
        const response = await API_UPDATE_ADMIN(data);
        const res = response.data;
        if (res.code === '0000') {
          vm.$swal({
            icon: 'success',
            title: '成功!',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_admin_list();
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 更新群組 */
    async update_group() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('group_detail');
      if (validateResult) {
        const data = vm.group_detail;
        const response = await API_UPDATE_GROUP_AUTHORITY(data);
        const res = response.data;
        if (res.code === '0000') {
          vm.$swal({
            icon: 'success',
            title: '成功!',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_group_authority();
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 更新管理者密碼 */
    async update_pwd() {
      const vm = this;
      const response = await API_UPDATE_ADMIN_PWD(vm.admin_detail.password);
      const res = response.data;
      if (res.code === '0000') {
        vm.$swal({
          icon: 'success',
          title: '成功!',
          text: res.text,
          confirmButtonText: '關閉視窗',
        });
        await vm.get_admin_list();
      }
    },
    /* 選群組後代入預設值 */
    async default_group_setting(groupCode) {
      const vm = this;
      let defaultGroup = {};
      vm.groups.forEach((item) => {
        if (item.groupCode === groupCode) {
          defaultGroup = item;
        }
      });
      for (const key of Object.keys(defaultGroup)) {
        vm.admin_detail[key] = defaultGroup[key];
      }
    },
  },
};
</script>
